<template>
  <div>
    <Head />
    <div class="bg">
      <p>NEWS</p>
      <p class="new">新闻资讯</p>
    </div>
    <div class="back">
      <div class="back-right" @click="goBack">＜返回</div>
    </div>
    <div class="content">
      <div>
        <span class="news" :style="{ background: content.color }">{{
          content.tag
        }}</span>
        <span class="time"> {{ time }}</span>
      </div>
      <h3>{{ content.title }}</h3>
      <p class="html-content" v-html="content.content" />
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '../modules/Head.vue'
import Foot from '../modules/Foot.vue'
import { reqGetContent, reqGetSetting } from '@/api/news'
export default {
  name: 'NewContent',
  components: { Head, Foot },
  data() {
    return {
      time: '',
      content: '',
      loading: false,
      querySetting: {
        key: 'article_type'
      }
    }
  },
  created() {
    this.getting()
  },
  methods: {
    async getting() {
      this.loading = true
      const id = this.$route.query.id
      const res = await reqGetContent(id)
      const resSetting = await reqGetSetting(this.querySetting)
      const resSettingJson = JSON.parse(resSetting.data)
      var mapList = new Array()
      for (var i = 0; i < resSettingJson.length; i++) {
        if (resSettingJson[i].key === 'gg.com') {
          mapList = resSettingJson[i].value
          break
        }
      }
      var newsKindMap = new Map()
      var newsKindColorMap = new Map()
      for (var j = 0; j < mapList.length; j++) {
        newsKindMap.set(mapList[j].value, mapList[j].label)
        if (mapList[j].value === 'news') {
          newsKindColorMap.set(mapList[j].value, '#2394C5')
        } else if (mapList[j].value === 'gameWalkthrough') {
          newsKindColorMap.set(mapList[j].value, '#6A22CD')
        } else if (mapList[j].value === 'announcement') {
          newsKindColorMap.set(mapList[j].value, '#EB3644')
        }
      }
      if (newsKindMap.has(res.data.tag)) {
        res.data.color = newsKindColorMap.get(res.data.tag)
        res.data.tag = newsKindMap.get(res.data.tag)
      }

      this.content = res.data
      this.time = new Date(this.content.createTime)
        .format('yyyy-MM-dd')
      this.loading = false
    },
    goBack() {
      window.history.go(-1)
    }
  }
}
</script>

<style scoped>
.bg {
   padding-top:3.575rem;
  background: url('../../assets/image/mobile/index/newbg.png') no-repeat;
  background-position: 1rem 3.575rem;
  background-size: auto 142.5px;
  background-position-x: 50%;
}
p:first-child {
  padding-top: 3.9375rem;
  font-size: 2.5rem;
  line-height: 2.9375rem;
  opacity: 0.76;
  color: #fff;
  font-family: Poppons !important;
  margin: 0;
  text-align: center;
}
.new {
  font-size: 1.125rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #fff;
  line-height: 1.3125rem;
  margin: 0;
  text-align: center;
  padding-bottom:.75rem
}
.bg p {
  font-weight: bold;
}
.back {
  height: 2.3125rem;
  line-height: 2.3125rem;
  padding-left: 1rem;
}
.back-right {
  width: 4.5rem;
  border-right: 0.125rem rgb(231, 230, 227, 0.5) solid;
}
.back-right a {
  color: #909299;
}
h3 {
  margin: 0.5rem auto 0.7rem;
}
.news {
  font-size: 0.4375rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.4688rem;
  padding: 0.0625rem 0.1875rem;
  margin-right: 0.3125rem;
}
.time {
  font-size: .625rem;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #21211d;
  line-height: 1.3125rem;
}
.content {
  background-color: #e8e6e7;
  padding: 1.5rem 1rem;
}
.content img {
  width: 100%;
  margin: 0.4rem auto;
}
.content p {
  font-size: 0.8125rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #545454;
  line-height: 1.25rem;
  padding: 0 0.125rem;
}
.html-content ::v-deep img,
.html-content ::v-deep video {
  max-width: 100%;
  margin: 0.5rem auto;
}
.html-content ::v-deep p {
  word-wrap: break-word;
}
p h3 {
  margin-top: 0.5rem;
}
</style>
